export function KeysDialog(dialog) {

	// Private Variables
	var that = this; // generic pointer back to this function
    
	// Public variables
    
    this.show = function(sysConfig) { 
            dialog.modal('show');
    }
 
 	// Buttons


}
